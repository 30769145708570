<template>
    <div class="header">
        <div class="px-4 py-1 lg:px-8 xl:px-12 md:container md:mx-auto">
            <div class="flex items-center justify-between">
                <nuxt-link to="/">
                    <div class="logo">
                        <img src="~/assets/images/logo-dark.png" alt="logo">
                    </div>
                </nuxt-link>
                <div class="hidden lg:block" :class="{ 'mobile-menu': showMenu }">
                    <ul class="links">
                        <li><nuxt-link to="/">Home</nuxt-link></li>
                        <li class="has-subnav">
                            <nuxt-link to="/recruitment">Recruitment</nuxt-link>
                            <ul class="subnav">
                                <li><nuxt-link to="/recruitment">Register</nuxt-link></li>
                                <li><nuxt-link to="/jobs">Find a Job</nuxt-link></li>
                            </ul>
                        </li>
                        <li><nuxt-link to="/about">About Us</nuxt-link></li>
                        <li><nuxt-link to="/contact">Contact</nuxt-link></li>
                    </ul>
                </div>
                <a class="burger-menu lg:hidden" @click="toggleMenu()">
                    <span class="menu-line line-top" />
                    <span class="menu-line" />
                    <span class="menu-line line-bottom" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Navigation',
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
});
</script>

<style lang="scss" scoped>
.header {
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2023;
    background-color: #FEFEFE;
    border-bottom: 1px solid #EEEEEE;
    .logo {
        height: 90px;
        img {
            height: 100%;
        }
    }
    @media (max-width: 767px),
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
        height: 64px;
        .logo {
            height: 56px;
        }
    }
    @media (max-width: 767px), (min-device-width: 390px) 
      and (max-device-width: 844px) 
      and (-webkit-min-device-pixel-ratio: 3)
      and (orientation: landscape) { 
        height: 64px;
        .logo {
            height: 56px;
        }
    }
    ul.links {
      li {
        padding: 1.38rem 0;
        position: relative;
        display: inline-block;
        margin-right: 2rem;
        font-size: 1.1rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: 400;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        a {
            color: #313131;
            cursor: pointer;
            &.router-link-active {
                color: $brand-primary;
            }
        }
        &.has-subnav {
            padding-right: 28px;
            &:after {
                content: url('/images/icons/down.svg');
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 20px;
            }
        }
        ul.subnav {
            z-index: 2021;
            white-space: nowrap;
            height: auto;
            position: absolute;
            text-align: left;
            min-width: 200px;
            top: 80%;
            left: 0;
            background-color: #f4f4f3;
            visibility: hidden;
            li {
                font-size: 0.875rem;
                display: block;
                margin-right: 0;
                color: $brand-primary;
                cursor: pointer !important;
                padding: 0;
                a {
                    line-height: 48px;
                    padding: 8px 16px;
                }
                &:hover {
                    background-color: $brand-secondary;
                    color: $white;
                    a {
                        color: $white;
                    }
                }
            }
        }
        &:hover {
          ul.subnav {
              visibility: visible;
              z-index: 2021;
          }
          @media (max-width: 767px),
            (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
            ul.subnav {
              position: relative;
            }
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            ul.subnav {
              position: relative;
            }
          }
        }
        @media (max-width: 767px),(min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          &.has-subnav {
            &:after {
              content: url('/images/icons/down.svg');
              position: absolute;
              right: 8px;
              top: 10px;
            }
          }
          ul {
            padding: 0;
          }
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
          &.has-subnav {
            &:after {
              content: url('/images/icons/down.svg');
              position: absolute;
              right: 8px;
              top: 10px;
            }
          }
        }
      }
    }
    
    .burger-menu {
    width: 28px;
    height: 20px;
    position: absolute;
    right: 3rem;
    z-index: 10000;
    overflow: hidden;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    .line-top {
      margin-top: -8px;
    }
    .line-mid {
    }
    .line-bottom {
      margin-top: 8px;
    }
    .menu-line {
      width: 100%;
      height: 2px;
      display: block;
      background-color: $brand-secondary;
      top: 50%;
      position: absolute;
      z-index: 5;
    }
    @media (max-width: 767px),
      (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      right: 1.5rem;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      right: 3rem;
    }
  }
  .mobile-menu {
    display: block;
    height: auto;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    ul {
      display: block;
      text-align: left;
      padding: 0 16px;
      li {
        display: block !important;
        line-height: 56px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>