<template>
    <footer class="bg-secondary text-white">
        <div class="px-4 lg:px-12 md:container md:mx-auto py-8 lg:py-12">
            <div class="grid lg:grid-cols-3 gap-16">
                <div>
                    <div class="logo">
                        <img src="~/assets/images/logo-light.png" alt="">
                    </div>
                    <p class="mt-8">Find your next great opportunity in the childcare industry that matches your skills, experience, and career goals.</p>
                </div>
                <div>
                    <h5 class="text-2xl font-medium text-primary">Quick Links</h5>
                    <ul class="mt-8 quick-links">
                        <li><nuxt-link to="/" class="text-lg">Home</nuxt-link></li>
                        <li><nuxt-link to="/recruitment" class="text-lg">Recruitment</nuxt-link></li>
                        <li><nuxt-link to="/about" class="text-lg">About Us</nuxt-link></li>
                        <li><nuxt-link to="/contact" class="text-lg">Contact Us</nuxt-link></li>
                    </ul>
                </div>
                <div>
                    <h5 class="text-2xl font-medium text-primary">Get In Touch</h5>
                    <div class="mt-8">
                        <p>Unit C, Tillermans Court, <br />Grenan Square, Greenford, UB6 0FT</p>
                        <p class="mt-4">Call us: <a href="tel:01753444008" class="text-xl">01753 444 008</a></p>
                        <p class="mt-4">Email us: <a href="mailto:admin@early-years.com" class="text-xl">admin@early-years.com</a></p>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="flex justify-between flex-wrap gap-4 lg:gap-0">
                <p>© {{ currentYear }} Early Years Recruitment.</p>
                <ul class="other-links">
                    <li><nuxt-link to="/terms-and-conditions">Terms and Conditions</nuxt-link></li>
                    <li><nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { computed } from 'vue';
const currentYear = computed(() => new Date().getFullYear());
</script>

<style lang="scss" scoped>
footer {
    color: #f7f7f7;
    .logo {
        width: 100%;
        img {
            height: 100px;
        }
    }
    ul.quick-links {
        li {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ul.other-links {
        li {
            display: inline;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>