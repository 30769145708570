<template>
    <div id="layout">
        <Header />
        <div class="main-page">
            <slot />
        </div>
        <Footer />
    </div>
</template>
<style lang="scss" scoped>
#layout {
    font-family: $font-family-base;
    font-weight: 300;
    font-size: $font-size-base;
    margin: 0;
    padding: 0;
    .main-page {
        margin-top: 100px;
        @media (max-width: 767px),
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        margin-top: 65px;
        }
        @media (max-width: 767px), (min-device-width: 390px) 
            and (max-device-width: 844px) 
            and (-webkit-min-device-pixel-ratio: 3)
            and (orientation: landscape) { 
                margin-top: 64px;
            }
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        margin-top: 65px;
        }
    }
    width: 100%;
    overflow-x: hidden;
}
</style>